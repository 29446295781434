import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
import MyMenu from './MyMenu';
import Card from './Card';
import Stamp from './Stamp';
import Coupon from './Coupon';
import Store from './Store';

const cx = classNames.bind(styles);

const Page = () => {
  return (
    <div className={cx('container')}>
      <MyMenu />
      <Card />
      <Stamp />
      <Coupon />
      <Store />
    </div>
  );
};

export default Page;
