import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import styles from './Stamp.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const stampRefs = useRef([]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (location.search.includes('stamp')) {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      stampRefs.current.forEach((stamp, index) => {
        setTimeout(() => {
          stamp.classList.add(cx('stamped'));
        }, index * 200);
      });
    } else {
      setTimeout(() => {
        stampRefs.current.forEach((stamp) => {
          stamp.classList.remove(cx('stamped'));
        });
      }, 500);
    }
  }, [isOpen]);

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggleOpen}>
        <img src={require('./my-title-stamp.png')} alt="" className={cx('title')} />
        <img src={require('./my-row-arrow.png')} alt="" className={cx(['arrow', { close: isOpen }])} />
      </div>
      <div className={cx(['body', { open: isOpen }])}>
        <img src={require('./my-stamp-board.png')} alt="" />
        <div className={cx('board')}>
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[0] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[1] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[2] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[3] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[4] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[5] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[6] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[7] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx('stamp')}
            ref={(el) => (stampRefs.current[8] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx(['stamp', 'hide'])}
            ref={(el) => (stampRefs.current[9] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx(['stamp', 'hide'])}
            ref={(el) => (stampRefs.current[10] = el)}
          />
          <img
            src={require('./my-stamp-item.png')}
            alt=""
            className={cx(['stamp', 'hide'])}
            ref={(el) => (stampRefs.current[11] = el)}
          />
        </div>
      </div>
    </div>
  );
};

export default Page;
