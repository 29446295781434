import React from 'react';
import classNames from 'classnames/bind';

import styles from './MyMenu.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  return (
    <div className={cx('container')}>
      <img src={require('./my-title-mymenu.png')} alt="" className={cx('title')} />
      <div className={cx('right')}>
        <img src={require('./my-row-caret.png')} alt="" className={cx('caret')} />
        <img src={require('./my-row-add.png')} alt="" className={cx('add')} />
      </div>
    </div>
  );
};

export default Page;
