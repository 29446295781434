import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import useInterval from '@restart/hooks/useInterval';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const COLORS = ['#ffec90', '#97c75c', '#58b0e3'];

const Page = () => {
  const [page, setPage] = useState(0);
  const slidersRef = useRef();

  useInterval(() => {
    setPage((prevPage) => (prevPage + 1) % COLORS.length);
  }, 2000);

  useEffect(() => {
    if (slidersRef.current) {
      slidersRef.current.scrollTo({
        left: page * window.innerWidth,
        behavior: 'smooth',
      });
    }
  }, [page]);

  return (
    <div className={cx('container')} style={{ backgroundColor: COLORS[page] }}>
      <div className={cx('top')}>
        <img src={require('./product-store.png')} alt="" className={cx('store')} />
        <img src={require('./product-next.png')} alt="" className={cx('next')} />
        <img src={require('./product-btn.png')} alt="" className={cx('btn')} />
        <div ref={slidersRef} className={cx('sliders')}>
          <div className={cx('slider')}>
            <img src={require('./product-donut-1.png')} alt="" />
            <div className={cx('shadow')} />
          </div>
          <div className={cx('slider')}>
            <img src={require('./product-donut-2.png')} alt="" />
            <div className={cx('shadow')} />
          </div>
          <div className={cx('slider')}>
            <img src={require('./product-donut-3.png')} alt="" />
            <div className={cx('shadow')} />
          </div>
        </div>
      </div>
      <div className={cx('bottom')}>
        <img src={require('./product-txt.png')} alt="" />
      </div>
    </div>
  );
};

export default Page;
