import React from 'react';
import classNames from 'classnames/bind';

import styles from './Store.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  return (
    <div className={cx('container')}>
      <img src={require('./my-title-store.png')} alt="" className={cx('title')} />
      <img src={require('./my-row-arrow.png')} alt="" className={cx('arrow')} />
    </div>
  );
};

export default Page;
