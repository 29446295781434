import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import useInterval from '@restart/hooks/useInterval';

import 'swiper/css';
import 'swiper/css/effect-cards';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const [page, setPage] = useState(0);
  const swiperRef = useRef();

  useInterval(() => {
    if (swiperRef.current.activeIndex < 3) {
      swiperRef.current.slideNext(500);
    } else {
      swiperRef.current.slideTo(0, 500);
    }

    setPage(swiperRef.current.activeIndex);
  }, 2000);

  return (
    <div className={cx('container')}>
      <div className={cx('background')} />
      <img src={require('./news-news.png')} alt="" className={cx('news')} />
      <img src={require('./news-illust.png')} alt="" className={cx('illust')} />
      <Swiper
        effect={'cards'}
        modules={[EffectCards]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        className={cx('swiper')}>
        <SwiperSlide className={cx('swiperSlide')}>
          <img src={require('./news-card-1.png')} alt="" />
        </SwiperSlide>
        <SwiperSlide className={cx('swiperSlide')}>
          <img src={require('./news-card-2.png')} alt="" />
        </SwiperSlide>
        <SwiperSlide className={cx('swiperSlide')}>
          <img src={require('./news-card-3.png')} alt="" />
        </SwiperSlide>
        <SwiperSlide className={cx('swiperSlide')}>
          <img src={require('./news-card-4.png')} alt="" />
        </SwiperSlide>
      </Swiper>
      <div className={cx('page')}>
        {page + 1}&nbsp;<span>/ 4</span>
      </div>
    </div>
  );
};

export default Page;
