import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Link from './pages/link';
import LotteWorld from './pages/lotteworld';
import Bingo from './pages/bingo';
import Survey from './pages/survey';
import CouponPack from './pages/couponpack';
import WebView20230331 from './pages/webview/20230331';
import WebView20230426 from './pages/webview/20230426';
import WebView20230517 from './pages/webview/20230517';
import WebView20230721 from './pages/webview/20230721';
import WebView20230810 from './pages/webview/20230810';
import WebView20231130 from './pages/webview/20231130';
import WebView20240118 from './pages/webview/20240118';
import WebView20240119 from './pages/webview/20240119';
import WebView20240315 from './pages/webview/20240315';
import WebView20240408 from './pages/webview/20240408';
import WebView20240626 from './pages/webview/20240626';
import WebView20240812 from './pages/webview/20240812';
import WebView20240909 from './pages/webview/20240909';
import WebView20241127 from './pages/webview/20241127';
import WebView20241212 from './pages/webview/20241212';
import WebView20250212 from './pages/webview/20250212';
import WebView20250304 from './pages/webview/20250304';
import PrivacyPolicy from './pages/agreements/privacy-policy';
import TermsOfUse from './pages/agreements/terms-of-use';
import TestA from './pages/test/A';
import TestB from './pages/test/B';
import TestC from './pages/test/C';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/link/*" element={<Link />} />

        <Route exact path="/lotteworld" element={<LotteWorld />} />
        <Route exact path="/bingo/:id" element={<Bingo />} />
        <Route exact path="/survey/:id" element={<Survey />} />
        <Route exact path="/couponpack/:id" element={<CouponPack />} />

        <Route path="webview">
          <Route exact path="20230331" element={<WebView20230331 />} />
          <Route exact path="20230426" element={<WebView20230426 />} />
          <Route exact path="20230517" element={<WebView20230517 />} />
          <Route exact path="20230721" element={<WebView20230721 />} />
          <Route exact path="20230810" element={<WebView20230810 />} />
          <Route exact path="20231130" element={<WebView20231130 />} />
          <Route exact path="20240118" element={<WebView20240118 />} />
          <Route exact path="20240119" element={<WebView20240119 />} />
          <Route exact path="20240315" element={<WebView20240315 />} />
          <Route exact path="20240408" element={<WebView20240408 />} />
          <Route exact path="20240626" element={<WebView20240626 />} />
          <Route exact path="20240812" element={<WebView20240812 />} />
          <Route exact path="20240909" element={<WebView20240909 />} />
          <Route exact path="20241127" element={<WebView20241127 />} />
          <Route exact path="20241212" element={<WebView20241212 />} />
          <Route exact path="20250212" element={<WebView20250212 />} />
          <Route exact path="20250304" element={<WebView20250304 />} />
        </Route>

        <Route path="agreements">
          <Route exact path="privacy-policy.html" element={<PrivacyPolicy />} />
          <Route exact path="terms-of-use.html" element={<TermsOfUse />} />
        </Route>

        <Route path="test">
          <Route exact path="A" element={<TestA />} />
          <Route exact path="B" element={<TestB />} />
          <Route exact path="C" element={<TestC />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
