import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import styles from './Card.module.scss';

const cx = classNames.bind(styles);

const AnimatedAmount = ({ targetAmount, isOpen }) => {
  const [amount, setAmount] = useState(0);
  const rafRef = useRef();

  useEffect(() => {
    if (!isOpen) {
      setAmount(targetAmount);
      return;
    }

    let start = null;
    const duration = 1000;

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const progressRatio = Math.min(progress / duration, 1);
      const currentAmount = Math.floor(progressRatio * targetAmount);
      setAmount(currentAmount);

      if (progress < duration) {
        rafRef.current = requestAnimationFrame(animate);
      }
    };

    rafRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(rafRef.current);
  }, [targetAmount, isOpen]);

  return <div>{amount.toLocaleString()}</div>;
};

const Page = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (location.search.includes('card')) {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
  }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggleOpen}>
        <img src={require('./my-title-card.png')} alt="" className={cx('title')} />
        <img src={require('./my-row-arrow.png')} alt="" className={cx(['arrow', { close: isOpen }])} />
      </div>
      <div className={cx(['body', { open: isOpen }])}>
        <div className={cx('inner')}>
          <img src={require('./my-card.png')} alt="" />
          <div className={cx('amount')}>
            <AnimatedAmount targetAmount={10000} isOpen={isOpen} />
          </div>
          <div className={cx('buttons')}>
            <img src={require('./my-button-1.png')} alt="" />
            <img src={require('./my-button-2.png')} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
