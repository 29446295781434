import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useMountEffect from '@restart/hooks/useMountEffect';
import { MdCheck, MdContentCopy } from 'react-icons/md';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const isReal = window.location.hostname === 'theventi.apps.flyground.co.kr';
const HOST_URL = isReal ? 'https://theventi.apis.flyground.co.kr' : 'https://alpha-theventi.apis.flyground.co.kr';

const Page = () => {
  const [searchParams] = useSearchParams();
  const [info, setInfo] = useState({
    tickets: [],
    remainCount: 0,
    appliedCount: 0,
  });
  const [showPopup, setShowPopup] = useState();
  const [selectedType, setSelectedType] = useState();
  const [isProcessing, setProcessing] = useState(false);
  const [result, setResult] = useState();

  // 회원번호
  const memberNo = searchParams.get('memberNo');
  // 10030000007730544 : 김련호
  // 10030000007730565 : 이동화

  // 당첨 결과 이미지 정의
  const resultImage = useMemo(() => {
    if (!result) return;

    if (result.ticketType === 0) {
      return require('../../assets/images/lotteworld/popup-result-type-0.jpg');
    } else if (result.ticketType === 1) {
      return require('../../assets/images/lotteworld/popup-result-type-1.jpg');
    } else if (result.ticketType === 2) {
      return require('../../assets/images/lotteworld/popup-result-type-2.jpg');
    } else if (result.ticketType === 3) {
      return require('../../assets/images/lotteworld/popup-result-type-3.jpg');
    }

    return null;
  }, [result]);

  useMountEffect(() => {
    // 회원번호 없는 경우
    if (memberNo === null || memberNo === '') return;

    loadInfo();
  });

  const loadInfo = () => {
    axios
      .get(`${HOST_URL}/v1/promo/lotteworld?memberNo=${memberNo}`)
      .then(({ data }) => data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success) {
          setInfo(data);
        }
      });
  };

  const doOpen = () => {
    // 응모 가능 횟수 0인 경우
    if (info.remainCount === 0) {
      setShowPopup('CHECK');
      return;
    }

    setShowPopup('SELECT');
  };

  const selectType = (e) => {
    setSelectedType(Number(e.currentTarget.dataset.type));
  };

  const doSelectDone = () => {
    if (selectedType === undefined) {
      alert('랜덤박스를 선택해 주세요.');
      return;
    }

    if (isProcessing) return;
    setProcessing(true);

    axios
      .post(`${HOST_URL}/v1/promo/lotteworld/apply?memberNo=${memberNo}&type=${selectedType}`)
      .then(({ data }) => data)
      .then(({ success, data }) => {
        console.debug(success, data);

        //! For test only
        // setSelectedType();
        // setResult({ ticketCode: '98350007724381577000', ticketType: 0 });
        // setShowPopup('RESULT');
        // return;

        if (success) {
          setSelectedType();
          setResult(data);
          setShowPopup('RESULT');
          return;
        }
      })
      .finally(() => {
        setProcessing(false);
        loadInfo();
      });
  };

  const copyToClipboard = () => {
    const element = document.createElement('textarea');
    element.value = result.ticketCode;
    document.body.appendChild(element);

    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);

    alert('클립보드에 복사되었습니다.');
  };

  const showHistory = () => {
    setShowPopup('HISTORY');
  };

  const showResult = (e) => {
    const ticketCode = e.currentTarget.dataset.code;
    const ticketType = Number(e.currentTarget.dataset.type);

    setResult({ ticketCode, ticketType });
    setShowPopup('RESULT');
  };

  const closePopup = () => {
    setShowPopup();
  };

  const moveToOrder = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'NAVIGATE',
        screenName: 'PickupShopList',
      }),
    );
  };

  const moveToDetail = () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'EXTERNAL',
        url: 'https://www.lotteworld.com/gate.html',
      }),
    );
  };

  const moveToBook = () => {
    let url;
    if (result.ticketType === 0) {
      url =
        'https://mticket.lotteworld.com/web/main/loginView.do?ei_gsber=1210&ei_bukrs=1200&reUrl=https%3A%2F%2Fmticket.lotteworld.com%2Fweb%2Fcpn%2FcouponRegister.do%3Fei_gsber%3D1210%26ei_bukrs%3D1200%26?ei_gsber=1210&ei_bukrs=1200';
    } else if (result.ticketType === 1) {
      url =
        'https://mticket.lotteworld.com/web/main/loginView.do?ei_gsber=1270&ei_bukrs=1200&reUrl=https%3A%2F%2Fmticket.lotteworld.com%2Fweb%2Fcpn%2FcouponRegister.do%3Fei_gsber%3D1270%26ei_bukrs%3D1200%26?ei_gsber=1270&ei_bukrs=1200';
    } else if (result.ticketType === 2) {
      url =
        'https://mticket.lotteworld.com/web/main/loginView.do?ei_gsber=1240&ei_bukrs=1200&reUrl=https%3A%2F%2Fmticket.lotteworld.com%2Fweb%2Fcpn%2FcouponRegister.do%3Fei_gsber%3D1240%26ei_bukrs%3D1200%26?ei_gsber=1240&ei_bukrs=1200';
    } else if (result.ticketType === 3) {
      url =
        'https://mticket.lotteworld.com/web/main/loginView.do?ei_gsber=1250&ei_bukrs=1200&reUrl=https%3A%2F%2Fmticket.lotteworld.com%2Fweb%2Fcpn%2FcouponRegister.do%3Fei_gsber%3D1250%26ei_bukrs%3D1200%26?ei_gsber=1250&ei_bukrs=1200';
    }

    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'EXTERNAL', url }));
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/lotteworld/01.jpg')} alt="" />
      <div className={cx('background_01')}>
        <img src={require('../../assets/images/lotteworld/button-open.jpg')} alt="" onClick={doOpen} />
        {info.tickets.length > 0 && (
          <img src={require('../../assets/images/lotteworld/button-result.jpg')} alt="" onClick={showHistory} />
        )}
      </div>
      <div className={cx('background_01')}>
        <div className={cx('row')} style={{ marginTop: 24 }}>
          <div className={cx('label')}>현재 랜덤 박스 개수</div>
          <div className={cx('value')}>{info.remainCount}회</div>
        </div>
        <div className={cx('row')} style={{ marginBottom: 16 }}>
          <div className={cx('label')}>오픈된 박스</div>
          <div className={cx('value')}>{info.appliedCount}회</div>
        </div>
      </div>
      <img src={require('../../assets/images/lotteworld/02-2.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/03.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/04.jpg')} alt="" />
      <div className={cx('background_02')}>
        <img src={require('../../assets/images/lotteworld/button-concert.jpg')} alt="" onClick={moveToDetail} />
      </div>
      <img src={require('../../assets/images/lotteworld/06.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/07.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/08.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/09.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/10.jpg')} alt="" />
      <img src={require('../../assets/images/lotteworld/11.jpg')} alt="" />

      {/* 참여 조건을 확인해 주세요! */}
      {showPopup === 'CHECK' && (
        <div className={cx('layer')}>
          <div className={cx('popup')}>
            <div className={cx('close')} onClick={closePopup} />
            <img src={require('../../assets/images/lotteworld/popup-check.jpg')} alt="" />
            <div className={cx('buttonWrapper')} style={{ paddingBottom: 20 }}>
              <img src={require('../../assets/images/lotteworld/button-order.jpg')} alt="" onClick={moveToOrder} />
            </div>
          </div>
        </div>
      )}

      {/* 아래의 랜덤박스 중 하나를 선택해 주세요! */}
      {showPopup === 'SELECT' && (
        <div className={cx('layer')}>
          <div className={cx('popup')}>
            <div className={cx('close')} onClick={closePopup} />
            <img src={require('../../assets/images/lotteworld/popup-select-header.jpg')} alt="" />
            <div className={cx('grid')}>
              <div className={cx('cell')} data-type="0" onClick={selectType}>
                <img src={require('../../assets/images/lotteworld/popup-select-gift-0.jpg')} alt="" />
                <div className={cx(['label', { selected: selectedType === 0 }])}>
                  <div className={cx('circle')}>
                    <MdCheck size={16} color="white" />
                  </div>
                  <div className={cx('text')}>A</div>
                </div>
              </div>
              <div className={cx('cell')} data-type="1" onClick={selectType}>
                <img src={require('../../assets/images/lotteworld/popup-select-gift-1.jpg')} alt="" />
                <div className={cx(['label', { selected: selectedType === 1 }])}>
                  <div className={cx('circle')}>
                    <MdCheck size={16} color="white" />
                  </div>
                  <div className={cx('text')}>B</div>
                </div>
              </div>
              <div className={cx('cell')} data-type="2" onClick={selectType}>
                <img src={require('../../assets/images/lotteworld/popup-select-gift-2.jpg')} alt="" />
                <div className={cx(['label', { selected: selectedType === 2 }])}>
                  <div className={cx('circle')}>
                    <MdCheck size={16} color="white" />
                  </div>
                  <div className={cx('text')}>C</div>
                </div>
              </div>
              <div className={cx('cell')} data-type="3" onClick={selectType}>
                <img src={require('../../assets/images/lotteworld/popup-select-gift-3.jpg')} alt="" />
                <div className={cx(['label', { selected: selectedType === 3 }])}>
                  <div className={cx('circle')}>
                    <MdCheck size={16} color="white" />
                  </div>
                  <div className={cx('text')}>D</div>
                </div>
              </div>
            </div>
            <div className={cx('buttonWrapper')}>
              <img src={require('../../assets/images/lotteworld/button-select.jpg')} alt="" onClick={doSelectDone} />
            </div>
            <img src={require('../../assets/images/lotteworld/popup-select-footer.jpg')} alt="" />
          </div>
        </div>
      )}

      {/* 당첨을 축하합니다! */}
      {showPopup === 'RESULT' && (
        <div className={cx('layer')}>
          <div className={cx('popup')}>
            <div className={cx('close')} onClick={closePopup} />
            <img src={resultImage} alt="" />
            <div className={cx('fieldWrapper')}>
              <div className={cx('field')} onClick={copyToClipboard}>
                <div className={cx('code')}>{result.ticketCode}</div>
                <MdContentCopy size={20} />
              </div>
            </div>
            <div className={cx('buttonWrapper')}>
              <img src={require('../../assets/images/lotteworld/button-book.jpg')} alt="" onClick={moveToBook} />
            </div>
            <img src={require('../../assets/images/lotteworld/popup-result-footer.jpg')} alt="" />
          </div>
        </div>
      )}

      {/* 내 응모결과 보기 */}
      {showPopup === 'HISTORY' && (
        <div className={cx('layer')}>
          <div className={cx('popup')}>
            <div className={cx('close')} onClick={closePopup} />
            <img src={require('../../assets/images/lotteworld/popup-history-header.jpg')} alt="" />
            <div className={cx('history')}>
              {info.tickets.map((ticket, index) => (
                <div key={index} className={cx('row')}>
                  <div className={cx('code')}>{ticket.ticketCode}</div>
                  <div
                    className={cx('button')}
                    data-code={ticket.ticketCode}
                    data-type={ticket.ticketType}
                    onClick={showResult}>
                    확인
                  </div>
                </div>
              ))}
            </div>
            <div className={cx('buttonWrapper')} style={{ paddingBottom: 20 }}>
              <img src={require('../../assets/images/lotteworld/button-okay.jpg')} alt="" onClick={closePopup} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
