import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import useInterval from '@restart/hooks/useInterval';
import { useLocation } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import styles from './Coupon.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const swiperRef = useRef();

  useEffect(() => {
    if (location.search.includes('coupon')) {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
  }, []);

  useInterval(() => {
    if (swiperRef.current.activeIndex < 4) {
      swiperRef.current.slideNext(500);
    } else {
      swiperRef.current.slideTo(0, 500);
    }
  }, 2000);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggleOpen}>
        <img src={require('./my-title-coupon.png')} alt="" className={cx('title')} />
        <img src={require('./my-row-arrow.png')} alt="" className={cx(['arrow', { close: isOpen }])} />
      </div>
      <div className={cx(['body', { open: isOpen }])}>
        <Swiper
          effect={'coverflow'}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 120,
            modifier: 1,
            slideShadows: false,
          }}
          modules={[EffectCoverflow]}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          className={cx('swiper')}>
          <SwiperSlide className={cx('swiperSlide')}>
            <img src={require('./my-coupon.png')} alt="" className={cx('coupon')} />
          </SwiperSlide>
          <SwiperSlide className={cx('swiperSlide')}>
            <img src={require('./my-coupon.png')} alt="" className={cx('coupon')} />
          </SwiperSlide>
          <SwiperSlide className={cx('swiperSlide')}>
            <img src={require('./my-coupon.png')} alt="" className={cx('coupon')} />
          </SwiperSlide>
          <SwiperSlide className={cx('swiperSlide')}>
            <img src={require('./my-coupon.png')} alt="" className={cx('coupon')} />
          </SwiperSlide>
          <SwiperSlide className={cx('swiperSlide')}>
            <img src={require('./my-coupon.png')} alt="" className={cx('coupon')} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Page;
